:where([data-space-below-section-title-value="173"]) {
	--spacebelowtitle: 80px;
	--spacebelowtitlemobile: 30px;
	--spacebetweenitems: 40px;
	--imgwidth: 30%;
	--imgwidthmobile: 70%;
	--imgposition: 50%;
	--imgoverlayopacitymobile: 50%;
	--imgfadeduration: .3s;
	--inactiveopacity: .3;
	--activeinset: 20px;
	--bordersize: 1px;
	--firstbordersize: 0px;
	--lastbordersize: 0px;
	--linecolor: var(--tweak-line-block-line-color);
	--titlesizemobile: 1.3rem;
	--buttonsizemobile: 1.1rem;
}

#siteWrapper .list-hover-layout,
#siteWrapper .page-section:has([data-space-below-section-title-value="173"]) {
	z-index: 5;
}

#siteWrapper .list-hover-layout .content-wrapper,
#siteWrapper .page-section:has([data-space-below-section-title-value="173"]) .content-wrapper {
	z-index: 9999;
}

#siteWrapper .list-hover-layout .user-items-list,
#siteWrapper .page-section:has([data-space-below-section-title-value="173"]) .user-items-list {
	overflow-x: clip;
}

#siteWrapper .list-hover-layout .list-section-title,
#siteWrapper .page-section:has([data-space-below-section-title-value="173"]) .list-section-title {
	padding-bottom: var(--spacebelowtitle) !important;
}

#siteWrapper .list-hover-layout .list-section-title:has(p:empty),
#siteWrapper .page-section:has([data-space-below-section-title-value="173"]) .list-section-title:has(p:empty) {
    display: none;
}

#siteWrapper .list-hover-layout .user-items-list-carousel__gutter,
#siteWrapper .page-section:has([data-space-below-section-title-value="173"]) .user-items-list-carousel__gutter {
	padding: 0 !important;
	pointer-events: none;
	overflow: visible !important;
	cursor: unset;
}

#siteWrapper .list-hover-layout .user-items-list-carousel__slideshow-holder,
#siteWrapper .page-section:has([data-space-below-section-title-value="173"]) .user-items-list-carousel__slideshow-holder {
	padding-inline: var(--sqs-site-gutter);
}

#siteWrapper .list-hover-layout .user-items-list-carousel__slides-revealer,
#siteWrapper .page-section:has([data-space-below-section-title-value="173"]) .user-items-list-carousel__slides-revealer {
	overflow: visible !important;
}

#siteWrapper .list-hover-layout .desktop-arrows,
#siteWrapper .page-section:has([data-space-below-section-title-value="173"]) .desktop-arrows {
	display: none;
}

#siteWrapper .list-hover-layout ul,
#siteWrapper .page-section:has([data-space-below-section-title-value="173"]) ul {
	grid-template-columns: 1fr;
	grid-gap: 0 !important;
	margin-inline: auto;
	position: relative;
	width: unset !important;
}

#siteWrapper .list-hover-layout ul > :is(li, a, div),
#siteWrapper .page-section:has([data-space-below-section-title-value="173"]) ul > :is(li, a, div) {
	margin-top: calc(var(--bordersize) * -1);
}

#siteWrapper .list-hover-layout .list-item,
#siteWrapper .page-section:has([data-space-below-section-title-value="173"]) .list-item {
	transition: opacity 0.5s ease !important;
	transform: none !important;
	grid-row: unset;
	grid-column: unset;
	pointer-events: auto;
}

#siteWrapper .list-hover-layout ul > :is(li, a, div):first-child,
#siteWrapper .page-section:has([data-space-below-section-title-value="173"]) ul > :is(li, a, div):first-child {
	margin-top: 0;
}

#siteWrapper .list-hover-layout ul > :is(li, a, div):first-child .list-item-content,
#siteWrapper .page-section:has([data-space-below-section-title-value="173"]) ul > :is(li, a, div):first-child .list-item-content {
	border-top-width: var(--firstbordersize) !important;
}

#siteWrapper .list-hover-layout ul > :is(li, a, div):last-child .list-item-content,
#siteWrapper .page-section:has([data-space-below-section-title-value="173"]) ul > :is(li, a, div):last-child .list-item-content {
	border-bottom-width: var(--lastbordersize) !important;
}

#siteWrapper .list-hover-layout .list-item-media,
#siteWrapper .page-section:has([data-space-below-section-title-value="173"]) .list-item-media,
#siteWrapper .list-hover-layout .user-items-list-carousel__media-container,
#siteWrapper .page-section:has([data-space-below-section-title-value="173"]) .user-items-list-carousel__media-container {
	opacity: 0;
	position: absolute;
	top: 0;
	left: var(--imgposition);
	transform: translate(-50%, -50%);
	pointer-events: none;
	max-width: var(--imgwidth);
	z-index: 2;
	will-change: translate;
	translate: calc(var(--mouse-x) / 7) var(--mouse-y);
	margin-bottom: 0 !important;
	transition: opacity var(--imgfadeduration) ease;
}

#siteWrapper .list-hover-layout .list-item-content,
#siteWrapper .page-section:has([data-space-below-section-title-value="173"]) .list-item-content {
	display: flex;
	justify-content: space-between;
	padding-block: var(--spacebetweenitems);
	border-block: var(--bordersize) solid var(--linecolor);
	opacity: 1;
	transition: opacity 0.5s ease;
}

#siteWrapper .list-hover-layout [data-body-placement="left"] .list-item-content__text-wrapper:has(.list-item-content__description),
#siteWrapper .page-section:has([data-space-below-section-title-value="173"]) [data-body-placement="left"] .list-item-content__text-wrapper:has(.list-item-content__description) {
	align-items: flex-start!important;
  flex-direction: column;
}

@media screen and (max-width: 767px) {
  #siteWrapper .list-hover-layout [data-body-placement="left"] .list-item-content:has(.list-item-content__description),
#siteWrapper .page-section:has([data-space-below-section-title-value="173"]) [data-body-placement="left"] .list-item-content:has(.list-item-content__description) {
  flex-direction: column;
 }
    #siteWrapper .list-hover-layout [data-body-placement="left"] .list-item-content__description,
#siteWrapper .page-section:has([data-space-below-section-title-value="173"]) [data-body-placement="left"] .list-item-content__description {
  max-width: 100%!important;
 }
}

#siteWrapper .list-hover-layout .list-item-content__text-wrapper,
#siteWrapper .page-section:has([data-space-below-section-title-value="173"]) .list-item-content__text-wrapper {
	display: flex;
	justify-content: space-between;
	flex: 1;
}

#siteWrapper .list-hover-layout .list-item-content__title,
#siteWrapper .page-section:has([data-space-below-section-title-value="173"]) .list-item-content__title {
	margin: 0;
	text-align: left;
	width: 100%;
	max-width: 60% !important;
	transition: transform 0.5s ease 0s !important;
}

#siteWrapper .list-hover-layout .list-item-content__button-wrapper,
#siteWrapper .page-section:has([data-space-below-section-title-value="173"]) .list-item-content__button-wrapper {
	display: flex;
}

#siteWrapper .list-hover-layout:not(:has(.list-item-content__description)) .list-item-content__button-container,
#siteWrapper .page-section:has([data-space-below-section-title-value="173"]):not(:has(.list-item-content__description)) .list-item-content__button-container {
	margin-top: 0 !important;
}

#siteWrapper .list-hover-layout .list-item-content__button-container,
#siteWrapper .page-section:has([data-space-below-section-title-value="173"]) .list-item-content__button-container {
	text-align: right;
	margin-right: 0 !important;
	max-width: 100% !important;
}

@media screen and (min-width: 768px) {
  #siteWrapper .list-hover-layout:has(.list-item-content__description) .list-item-content__button-container,
  #siteWrapper .page-section:has([data-space-below-section-title-value="173"]):has(.list-item-content__description) .list-item-content__button-container {
    margin-top: 0 !important;
 }
}

#siteWrapper .list-hover-layout .list-item-content__button,
#siteWrapper .page-section:has([data-space-below-section-title-value="173"]) .list-item-content__button {
	padding: 0;
	border: none;
	background: none;
	clip-path: none;
	color: var(--list-section-simple-description-color);
	transition: transform 0.5s ease;
	pointer-events: none !important;
}

#siteWrapper .list-hover-layout .list-item-content__button:before,
#siteWrapper .page-section:has([data-space-below-section-title-value="173"]) .list-item-content__button:before,
#siteWrapper .list-hover-layout .list-item-content__button:after,
#siteWrapper .page-section:has([data-space-below-section-title-value="173"]) .list-item-content__button:after {
	display: none;
}

#siteWrapper .list-hover-layout [data-alignment-vertical="middle"] .list-item-content__text-wrapper,
#siteWrapper .page-section:has([data-space-below-section-title-value="173"]) [data-alignment-vertical="middle"] .list-item-content__text-wrapper {
	align-items: center;
}

#siteWrapper .list-hover-layout [data-alignment-vertical="middle"] .list-item-content__button-wrapper,
#siteWrapper .page-section:has([data-space-below-section-title-value="173"]) [data-alignment-vertical="middle"] .list-item-content__button-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

#siteWrapper .list-hover-layout [data-alignment-vertical="bottom"] .list-item-content__text-wrapper,
#siteWrapper .page-section:has([data-space-below-section-title-value="173"]) [data-alignment-vertical="bottom"] .list-item-content__text-wrapper {
	align-items: flex-end;
}

#siteWrapper .list-hover-layout [data-alignment-vertical="bottom"] .list-item-content__button-wrapper,
#siteWrapper .page-section:has([data-space-below-section-title-value="173"]) [data-alignment-vertical="bottom"] .list-item-content__button-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

#siteWrapper #page .list-hover-layout.can-hover ul:not(:has(.item-link:focus-visible)) .item-link:hover .list-item-media,
#siteWrapper #page .list-hover-layout.can-hover .item-link:focus-visible .list-item-media,
#siteWrapper #page .list-hover-layout.can-hover ul:not(:has(.item-link:focus-visible)) .item-link:hover .list-item-content,
#siteWrapper #page .list-hover-layout.can-hover .item-link:focus-visible .list-item-content,
#siteWrapper #page .list-hover-layout.can-hover ul:not(:has(.item-link:focus-visible)) .item-link:hover .user-items-list-carousel__media-container,
#siteWrapper #page .list-hover-layout.can-hover .item-link:focus-visible .user-items-list-carousel__media-container {
	opacity: 1 !important;
}

#siteWrapper #page .list-hover-layout.can-hover ul:not(:has(.item-link:focus-visible)) .item-link:hover .list-item-content__title,
#siteWrapper #page .list-hover-layout.can-hover .item-link:focus-visible .list-item-content__title {
	transform: translate(var(--activeinset), 0);
}

#siteWrapper #page .list-hover-layout.can-hover ul:not(:has(.item-link:focus-visible)) .item-link:hover .list-item-content__button,
#siteWrapper #page .list-hover-layout.can-hover .item-link:focus-visible .list-item-content__button {
	transform: translate(calc(var(--activeinset) * -1), 0);
}

#siteWrapper #page .list-hover-layout.can-hover ul:has(.item-link:hover) .item-link:not(:focus-visible) .list-item-content {
	opacity: var(--inactiveopacity);
}

#siteWrapper #page .list-hover-layout.can-hover ul:has(.item-link:focus-visible) > .item-link:not(:focus-visible) .list-item-content {
	opacity: var(--inactiveopacity);
}

#siteWrapper #page .list-hover-layout.can-hover .item-link:focus-visible .list-item {
	position: relative;
}

#siteWrapper #page .list-hover-layout.can-hover .item-link:focus-visible :is(.list-item-media, .user-items-list-carousel__media-container) {
	top: 50%;
	translate: unset;
}

#siteWrapper #page .list-hover-layout.can-hover ul:focus-within :is(.list-item-media, .user-items-list-carousel__media-container) {
	transition-duration: 0s !important;
}

@media (hover: hover) and (prefers-reduced-motion) {
	#siteWrapper .list-hover-layout .list-item .list-item,
  #siteWrapper .page-section:has([data-space-below-section-title-value="173"]) .list-item {
		position: relative;
	}

	#siteWrapper .list-hover-layout .list-item .list-item-media,
  #siteWrapper .page-section:has([data-space-below-section-title-value="173"]) .list-item-media,
  #siteWrapper .list-hover-layout .list-item .user-items-list-carousel__media-container,
  #siteWrapper .page-section:has([data-space-below-section-title-value="173"]) .user-items-list-carousel__media-container {
		top: 50%;
		translate: unset !important;
	}

	#siteWrapper .list-hover-layout .list-item .list-item-content__title,
  #siteWrapper .page-section:has([data-space-below-section-title-value="173"]) .list-item-content__title,
  #siteWrapper .list-hover-layout .list-item .list-item-content__button,
  #siteWrapper .page-section:has([data-space-below-section-title-value="173"]) .list-item-content__button {
		transform: none !important;
	}
}

@media (hover: none) and (pointer: coarse), (max-width: 767px) {
	#siteWrapper .list-hover-layout .list-item-media,
  #siteWrapper .user-items-list[data-space-below-section-title-value="173"] .list-item-media,
  #siteWrapper .list-hover-layout .user-items-list-carousel__media-container,
  #siteWrapper .user-items-list[data-space-below-section-title-value="173"] .user-items-list-carousel__media-container {
		max-width: var(--imgwidthmobile);
	}

	#siteWrapper .list-hover-layout .list-section-title,
  #siteWrapper .user-items-list[data-space-below-section-title-value="173"] .list-section-title {
		padding-bottom: var(--spacebelowtitlemobile) !important;
	}

	#siteWrapper .list-hover-layout .user-items-list-carousel__slides-revealer,
  #siteWrapper .user-items-list[data-space-below-section-title-value="173"] .user-items-list-carousel__slides-revealer {
		padding-inline: var(--sqs-site-gutter);
	}

	#siteWrapper .list-hover-layout .user-items-list-carousel__slides,
  #siteWrapper .user-items-list[data-space-below-section-title-value="173"] .user-items-list-carousel__slides {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		padding-inline: 0 !important;
		overflow: hidden;
	}

	#siteWrapper .list-hover-layout .list-item-content__title,
  #siteWrapper .user-items-list[data-space-below-section-title-value="173"] .list-item-content__title,
  #siteWrapper .list-hover-layout .list-item-content__button-container,
  #siteWrapper .user-items-list[data-space-below-section-title-value="173"] .list-item-content__button-container {
		max-width: 100% !important;
		text-align: left;
	}

	#siteWrapper .list-hover-layout .user-items-list-carousel__gutter,
  #siteWrapper .user-items-list[data-space-below-section-title-value="173"] .user-items-list-carousel__gutter {
		padding: 0 !important;
	}

	#siteWrapper .list-hover-layout ul,
  #siteWrapper .user-items-list[data-space-below-section-title-value="173"] ul {
		grid-gap: 0 !important;
		padding-inline: var(--sqs-site-gutter);
	}

	#siteWrapper .list-hover-layout .user-items-list-carousel__slide,
  #siteWrapper .user-items-list[data-space-below-section-title-value="173"] .user-items-list-carousel__slide {
		border: none;
	}

	#siteWrapper .list-hover-layout ul > :is(li, a, div),
  #siteWrapper .user-items-list[data-space-below-section-title-value="173"] ul > :is(li, a, div) {
		margin-top: calc(var(--bordersize) * -1);
	}

	#siteWrapper .list-hover-layout ul > :is(li, a, div):first-child,
  #siteWrapper .user-items-list[data-space-below-section-title-value="173"] ul > :is(li, a, div):first-child {
		margin-top: 0;
	}

	#siteWrapper .list-hover-layout ul > :is(li, a, div):first-child .list-item,
  #siteWrapper .user-items-list[data-space-below-section-title-value="173"] ul > :is(li, a, div):first-child .list-item {
                border-top-width: var(--firstbordersize) !important;
	}

	#siteWrapper .list-hover-layout ul > :is(li, a, div):last-child .list-item,
  #siteWrapper .user-items-list[data-space-below-section-title-value="173"] ul > :is(li, a, div):last-child .list-item {
		border-bottom-width: var(--lastbordersize) !important;
	}

	#siteWrapper .list-hover-layout .list-item-content,
  #siteWrapper .user-items-list[data-space-below-section-title-value="173"] .list-item-content {
		position: relative;
	}

	#siteWrapper .list-hover-layout .list-item-content:before,
  #siteWrapper .user-items-list[data-space-below-section-title-value="173"] .list-item-content:before {
		content: "";
		position: absolute;
		inset: 0;
		background: var(--siteBackgroundColor);
		opacity: 0;
		transition: opacity 0.5s ease;
		z-index: -1;
	}

	#siteWrapper .list-hover-layout .item-link.active .list-item-content:before,
  #siteWrapper .user-items-list[data-space-below-section-title-value="173"] .item-link.active .list-item-content:before,
  #siteWrapper .list-hover-layout ul:not(:has(.item-link.active)) .item-link:hover .list-item-content:before,
  #siteWrapper .user-items-list[data-space-below-section-title-value="173"] ul:not(:has(.item-link.active)) .item-link:hover .list-item-content:before,
  #siteWrapper .list-hover-layout .item-link:focus-visible .list-item-content:before,
  #siteWrapper .user-items-list[data-space-below-section-title-value="173"] .item-link:focus-visible .list-item-content:before {
		opacity: var(--imgoverlayopacitymobile) !important;
	}

	#siteWrapper .list-hover-layout .list-item-media,
  #siteWrapper .user-items-list[data-space-below-section-title-value="173"] .list-item-media {
		position: fixed !important;
		z-index: -2;
		translate: 0 !important;
		transform: translate(-50%, -50%);
		top: 50%;
		left: 50%;
		pointer-events: none;
	}

	#siteWrapper .list-hover-layout .list-item-media-inner,
  #siteWrapper .user-items-list[data-space-below-section-title-value="173"] .list-item-media-inner {
		padding-bottom: 0 !important;
		height: 100% !important;
		margin: 0 auto;
		max-height: var(--imgmaxheight);
		aspect-ratio: 1 / 1;
	}

	#siteWrapper .list-hover-layout .list-item-media-inner[data-aspect-ratio="3:2"],
  #siteWrapper .user-items-list[data-space-below-section-title-value="173"] .list-item-media-inner[data-aspect-ratio="3:2"] {
		aspect-ratio: 3 / 2;
	}

	#siteWrapper .list-hover-layout .list-item-media-inner[data-aspect-ratio="2:3"],
  #siteWrapper .user-items-list[data-space-below-section-title-value="173"] .list-item-media-inner[data-aspect-ratio="2:3"] {
		aspect-ratio: 2 / 3;
	}

	#siteWrapper .list-hover-layout .list-item-media-inner[data-aspect-ratio="4:3"],
  #siteWrapper .user-items-list[data-space-below-section-title-value="173"] .list-item-media-inner[data-aspect-ratio="4:3"] {
		aspect-ratio: 4 / 3;
	}

	#siteWrapper .list-hover-layout .list-item-media-inner[data-aspect-ratio="3:4"],
  #siteWrapper .user-items-list[data-space-below-section-title-value="173"] .list-item-media-inner[data-aspect-ratio="3:4"] {
		aspect-ratio: 3 / 4;
	}

	#siteWrapper .list-hover-layout .list-item-media-inner[data-aspect-ratio="16:9"],
  #siteWrapper .user-items-list[data-space-below-section-title-value="173"] .list-item-media-inner[data-aspect-ratio="16:9"] {
		aspect-ratio: 16 / 9;
	}

	#siteWrapper .list-hover-layout .list-item-media-inner[data-aspect-ratio="2.4:1"],
  #siteWrapper .user-items-list[data-space-below-section-title-value="173"] .list-item-media-inner[data-aspect-ratio="2.4:1"] {
		aspect-ratio: 2.4 / 1;
	}

	#siteWrapper .list-hover-layout .item-link.active .list-item-media,
  #siteWrapper .user-items-list[data-space-below-section-title-value="173"] .item-link.active .list-item-media {
		z-index: -1;
		opacity: 1 !important;
	}

	#siteWrapper .list-hover-layout .item-link.active .list-item-content__title,
  #siteWrapper .user-items-list[data-space-below-section-title-value="173"] .item-link.active .list-item-content__title {
		transform: translate(var(--activeinset), 0);
	}

	#siteWrapper .list-hover-layout .item-link.active .list-item-content__button,
  #siteWrapper .user-items-list[data-space-below-section-title-value="173"] .item-link.active .list-item-content__button {
		transform: translate(calc(var(--activeinset) * -1), 0);
	}

	#siteWrapper .list-hover-layout ul:has(.item-link.active) .item-link:not(.active) .list-item-content,
  #siteWrapper .user-items-list[data-space-below-section-title-value="173"] ul:has(.item-link.active) .item-link:not(.active) .list-item-content {
		opacity: var(--inactiveopacity);
	}
}

@media screen and (max-width: 767px) {
	#siteWrapper #page .list-hover-layout .list-item-content__title,
  #siteWrapper #page .user-items-list[data-space-below-section-title-value="173"] .list-item-content__title {
		font-size: var(--titlesizemobile);
	}

	#siteWrapper #page .list-hover-layout .list-item-content__button,
  #siteWrapper #page .user-items-list[data-space-below-section-title-value="173"] .list-item-content__button {
		font-size: var(--buttonsizemobile);
	}
}
